<template>
  <v-container fill-height fill-width fluid class="pa-0 login-bg">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" md="6" align="center">
        <v-card color="primary" tile max-width="450" min-width="450" height="100"
                style="margin-bottom: -65px; z-index: 2">
          <v-img src="../assets/venus_media_collection_white.svg" height="100" contain></v-img>
        </v-card>
        <v-card elevation="0" tile max-width="500" min-width="500" height="300" class="pa-2">
          <v-card-title class="subtitle-2 mt-12 pb-0">Login</v-card-title>
          <v-form>
            <v-text-field label="Username" v-model="username" class="ma-2"></v-text-field>
            <v-text-field
                class="ma-2"
                @error=""
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                label="Password"
                hint="At least 8 characters"
                @click:append="show = !show"
            ></v-text-field>
            <v-btn class="mt-0" style="width: 100%" tile
                   type="submit"
                   @click.prevent="login"
                   outlined color="primary"> Login
            </v-btn>
          </v-form>
          <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="primary"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar text color="error" v-model="error">{{ $t('messages.somethingWentWrong') }}</v-snackbar>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "LoginLayout",
  data() {
    return {
      show: false,
      username: '',
      password: '',
      loading: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  methods: {
    ...mapActions({
      sendLogin: 'main/login',
      browserCheck: 'main/browserCheck',
    }),
    async login() {
      const data = new FormData()
      data.append('username', this.username)
      data.append('password', this.password)
      this.loading = true
      this.sendLogin(data).then((err) => {
        this.error = err
        this.loading = false
      })
    }
  },
  beforeMount() {
    this.browserCheck()
  }
}
</script>

<style lang="scss">
.login-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("https://webimages.we2p.de/static/cockpit_login/");
  background-position: center;
  background-size: cover;
}
</style>